<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="退款时间"
                >
                    <el-date-picker
                        v-model="queryFormModel.statDateDay"
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出excel
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="accountId"
                    label="会员ID"
                    min-width="100"
                />
                <el-table-column
                    prop="subscriberName"
                    label="海关订购人姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="deliveryConsignee"
                    label="收件人姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="orderSn"
                    label="订单号"
                    min-width="100"
                />
                <el-table-column
                    label="付款时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="退款时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.cancelOperateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="售后类型"
                    min-width="100"
                />
                <el-table-column
                    prop="payableAmount"
                    label="支付金额"
                    min-width="100"
                />
                <el-table-column
                    prop="refundAmount"
                    label="退款金额"
                    min-width="100"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'PayStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.PayStat.iHealthRefundStatList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        exportExcel() {
            let statBeginDate = null;
            let statEndDate = null;
            if (this.queryFormModel.statDateDay != null && this.queryFormModel.statDateDay.length === 2) {
                [statBeginDate, statEndDate] = this.queryFormModel.statDateDay;
            }

            this.$http.href('/stat/payStat/exportIHealthRefundStatExcel', {
                ...this.queryFormModel,
                statBeginDate,
                statEndDate,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
