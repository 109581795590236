<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                >
                    <el-date-picker
                        v-model="queryFormModel.statDateDay"
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentType"
                    label="付款方式"
                    v-if="queryFormModel.paymentMode == 0 || queryFormModel.paymentMode == 1"
                >
                    <el-select
                        v-model="queryFormModel.paymentType"
                        multiple
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in paymentTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <el-row
                class="second-header bc-t npa-a"
                :gutter="12"
            >
                <el-col :span="6">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="onPaymentMode(0)"
                        >
                            <div :class="['icon tc-w',+queryFormModel.paymentMode === 0?'bc-main':'bc-g6']">
                                <div class="f-icon">
                                    &#xf02c;
                                </div>
                                <div class="label">
                                    合计
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    订单金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ orderNumAmountStatMap0.payableAmount || 0 }}
                                </div>
                                <div>(订单数量 {{ orderNumAmountStatMap0.orderNum || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="onPaymentMode(1)"
                        >
                            <div :class="['icon tc-w',+queryFormModel.paymentMode === 1?'bc-second':'bc-g6']">
                                <div class="f-icon">
                                    &#xf02e;
                                </div>
                                <div class="label">
                                    线上支付
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    订单金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ orderNumAmountStatMap1.payableAmount || 0 }}
                                </div>
                                <div>(订单数量 {{ orderNumAmountStatMap1.orderNum || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="onPaymentMode(2)"
                        >
                            <div :class="['icon tc-w',+queryFormModel.paymentMode === 2?'bc-green':'bc-g6']">
                                <div class="f-icon">
                                    &#xf02f;
                                </div>
                                <div class="label">
                                    线下支付
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    订单金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ orderNumAmountStatMap2.payableAmount || 0 }}
                                </div>
                                <div>(订单数量 {{ orderNumAmountStatMap2.orderNum || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="onPaymentMode(3)"
                        >
                            <div :class="['icon tc-w',+queryFormModel.paymentMode === 3?'bc-green':'bc-g6']">
                                <div class="f-icon">
                                    &#xf02d;
                                </div>
                                <div class="label">
                                    退款
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    退款金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ orderNumAmountStatMap3.refundAmount || 0 }}
                                </div>
                                <div>(订单数量 {{ orderNumAmountStatMap3.refundNum || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出excel
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    label="付款日期"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderSn"
                    label="订单号"
                    min-width="100"
                />
                <el-table-column
                    prop="externalPaymentSn"
                    label="付款单号"
                    min-width="100"
                />
                <el-table-column
                    prop="payableAmount"
                    label="订单金额"
                    min-width="100"
                />
                <el-table-column
                    prop="refundAmount"
                    label="退款金额"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentTypeName"
                    label="付款方式"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTypeName }}<span v-if="scope.row.paymentComments">/{{ scope.row.paymentComments }}</span>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'PayStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                paymentMode: '0',
                statDateDay: '',
                paymentType: [],
            },
            // 表格数据
            tableData: [],
            orderNumAmountStatMap0: {},
            orderNumAmountStatMap1: {},
            orderNumAmountStatMap2: {},
            orderNumAmountStatMap3: {},
            paymentTypeList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.PayStat.payStatList(queryData).then(json => {
                const res = json.data.data.orderList;
                this.tableData = res.data;
                this.pagination = res.pagination;

                this.orderNumAmountStatMap0 = json.data.data.orderNumAmountStatMap0;
                this.orderNumAmountStatMap1 = json.data.data.orderNumAmountStatMap1;
                this.orderNumAmountStatMap2 = json.data.data.orderNumAmountStatMap2;
                this.orderNumAmountStatMap3 = json.data.data.orderNumAmountStatMap3;

                return Promise.resolve({ data: res });
            });
        },
        /* -- 事件 --*/
        onPaymentMode(mode) {
            if (this.queryFormModel.paymentMode === mode) return;
            this.queryFormModel.paymentMode = mode;
            this.onQuery(this.pagination);
        },
        initPaymentTypeList() {
            this.$api.Sp.Shop.getShopOnlinePaymentTypeList().then(json => {
                this.paymentTypeList = json.data.data;
            });
        },
        exportExcel() {
            let statBeginDate = null;
            let statEndDate = null;
            let paymentType = null;
            if (this.queryFormModel.statDateDay != null && this.queryFormModel.statDateDay.length === 2) {
                [statBeginDate, statEndDate] = this.queryFormModel.statDateDay;
            }
            if (Array.isArray(this.queryFormModel.paymentType)) {
                paymentType = this.queryFormModel.paymentType.join(',');
            }

            this.$http.href('/stat/payStat/exportPayStatExcel', {
                ...this.queryFormModel,
                paymentType,
                statBeginDate,
                statEndDate,
            });
        },
    },
    created() {
        this.queryFormModel.statDateDay = [this.$utils.getSysDayDate(), this.$utils.getSysDayDate()];
        this.initPaymentTypeList();
    },
};
</script>

<style lang="scss" scoped>
</style>
