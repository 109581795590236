<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="付款时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentType"
                    label="付款方式"
                >
                    <el-select
                        v-model="queryFormModel.paymentType"
                        multiple
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in paymentTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="paymentCheckStatus"
                    label="对账状态"
                >
                    <dictionaries-picker
                        type="paymentCheckStatus"
                        v-model="queryFormModel.paymentCheckStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b dp-fx fx-ai-c"
            >
                <el-button-group>
                    <el-upload
                        class="upload-demo dp-ib ma-l"
                        action="/ex/paymentCheck/importExcel"
                        :show-file-list="false"
                        accept=".csv"
                        :on-success="onImportSuccess"
                        :on-error="onImportError"
                        :on-progress="onImportProgress"
                    >
                        <el-button
                            size="small"
                            type="primary"
                        >
                            上传账单
                        </el-button>
                    </el-upload>
                </el-button-group>
                <el-alert
                    class="ma-l"
                    type="success"
                    :closable="false"
                >
                    <template slot="title">
                        <span>{{ alertContent }}</span>
                        <span
                            v-if="currentTableSelect.length !==0"
                            class="tc-primary ma-l"
                            @click="clearSelect"
                        >清空</span>
                    </template>
                </el-alert>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                ref="table"
                @selection-change="onTableSelectionChange"
                @sort-change="selectSortChange"
            >
                <el-table-column
                    type="selection"
                    width="39"
                />
                <el-table-column
                    prop="paymentSourceName"
                    label="付款单来源"
                    width="100"
                />
                <el-table-column
                    prop="orderSn"
                    label="订单号"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentSn"
                    label="商户付款单号"
                    width="180"
                />
                <el-table-column
                    prop="paymentSn"
                    label="商户付款单号"
                    width="180"
                />
                <el-table-column
                    prop="externalPaymentSn"
                    label="第三方付款单号"
                    width="210"
                />
                <el-table-column
                    prop="paymentTypeName"
                    label="付款方式"
                    min-width="100"
                />
                <el-table-column
                    label="付款时间"
                    width="135"
                    prop="paymentTime"
                    sortable="custom"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="paymentAmount"
                    label="付款金额"
                    min-width="100"
                />
                <el-table-column
                    prop="externalPaymentAmount"
                    label="第三方金额"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentCheckStatusName"
                    label="对账状态"
                    min-width="100"
                />
                <el-table-column
                    label="对账时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentCheckTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'PaymentCheck',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '',
                paymentType: [],
                paymentCheckStatus: '',
                orderByKey: '',
                orderByType: '',
            },
            // 表格数据
            tableData: [],
            paymentTypeList: [],
            loading: null,
            // 表格当前选中行
            currentTableSelect: [],
            statMap: {},
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.PaymentCheck.data(queryData).then(json => {
                const res = json.data.data.result;
                this.tableData = res.data;
                this.pagination = res.pagination;

                this.statMap = json.data.data.statMap;
                return Promise.resolve({ data: res });
            });
        },
        onImportSuccess(response) {
            this.loading.close();
            if (response.success) {
                this.$message({
                    message: response.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            } else {
                this.$alert(response.msg, '错误提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
            }
        },
        onImportError(err) {
            this.loading.close();

            this.$alert(err.msg, '错误提示', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
            });
        },
        onImportProgress() {
            this.loading = this.$loading({
                lock: true,
                text: '导入中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        },
        initPaymentTypeList() {
            this.$api.Sp.Shop.getShopPaymentTypeList({}).then(json => {
                this.paymentTypeList = json.data.data;
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        clearSelect() {
            const { table } = this.$refs;
            // 初始化已选择数据
            if (table) {
                table.clearSelection();
            }
        },
        selectSortChange(val) {
            if (val.order) {
                this.queryFormModel.orderByKey = val.prop;
                this.queryFormModel.orderByType = val.order === 'ascending' ? 'asc' : 'desc';
            } else {
                this.queryFormModel.orderByKey = '';
                this.queryFormModel.orderByType = '';
            }
            this.onQuery(this.pagination);
        },
    },
    computed: {
        alertContent() {
            let result = '';
            let map = {
                waitCheckAmount: 0,
                errorCheckAmount: 0,
                checkAmount: 0,
            };
            if (this.currentTableSelect.length !== 0) {
                this.currentTableSelect.forEach(item => {
                    if (item.paymentCheckStatus === 10) {
                        map.waitCheckAmount += item.paymentAmount;
                    } else if (item.paymentCheckStatus === 99) {
                        map.errorCheckAmount += item.paymentAmount;
                    } else if (item.paymentCheckStatus === 100) {
                        map.checkAmount += item.paymentAmount;
                    }
                });
                result = `已选择 ${this.currentTableSelect.length} 项`;
            } else if (this.statMap) {
                map = { ...this.statMap };
            }
            result = `${result}   待对账金额：${map.waitCheckAmount}  对账异常金额：${
                map.errorCheckAmount
            }  已对账金额：${map.checkAmount}`;
            return result;
        },
    },
    created() {
        this.initPaymentTypeList();
    },
};
</script>

<style lang="scss">
.demo-table-expand {
    font-size: 0;
}

.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
